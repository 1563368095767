<section class="existing-layers side-panel">
    <header>
        <h2 class="layers-heading" translate="TC.Common.TemplateLibrary"></h2>
        <gsp-button [type]="ButtonType.Icon" (action)="closeSidePanel()" iconClass="i24 icon_line_cancel"></gsp-button>
    </header>
    <section class="search">
        <input
            type="text"
            [formControl]="searchControl"
            placeholder="{{ searchPlaceholder | translate }}"
            class="gsp-form-input default"
        />
        <div
            class="filter-icon"
            [ngClass]="{
                active: searchModeDict[SearchMode.TEMPLATE_NAME] || searchModeDict[SearchMode.WORKSPACE_NAME]
            }"
            [matMenuTriggerFor]="filterContextMenu"
            (click)="openContextMenu($event)"
        >
            <i class="icon_solid_filter i24"></i>
        </div>
    </section>

    <section class="content add-layer-list">
        <custom-scroll
            [infiniteScroll]="true"
            [isPageLoading]="paginationLoading"
            (loadNextPage)="throttleLoadNextPage()"
            (viewportUpdated)="paginationLoading = false"
        >
            <loading *ngIf="loading" class="loading-align-center"></loading>
            <ul *ngIf="!loading" class="list-content">
                <li
                    class="no-hover"
                    *ngIf="!templates.length && !loading"
                    translate="TCS.Mapviewer.AddMenu.NoTemplatesFound"
                ></li>
                <li
                    *ngFor="let spatialItem of templates"
                    (click)="selectOrDeselectTemplate(spatialItem)"
                    [ngClass]="{ selected: spatialItem.selected }"
                >
                    <div class="layer-sym">
                        <geom-icon
                            path="sym_32-geom-"
                            color="#000000"
                            [geoType]="spatialItem.template.geometryType"
                        ></geom-icon>
                    </div>
                    <div class="layer-title">
                        <span title="{{ spatialItem.template.name }}">{{ spatialItem.template.name }}</span>
                        <!--Loading relationships-->
                        <ng-container
                            *ngIf="
                                spatialItem.workspaces &&
                                spatialItem.workspaces.length > 0 &&
                                spatialItem.workspaces.length < 2
                            "
                        >
                            <div class="relationship" title="{{ spatialItem.workspaces[0].name }}">
                                {{ spatialItem.workspaces[0].name }}
                            </div>
                        </ng-container>
                        <div
                            class="relationship"
                            *ngIf="spatialItem.workspaces && spatialItem.workspaces.length >= 2"
                            title="{{ 'TCS.Mapviewer.Template.Associations' | translate }}"
                        >
                            {{ 'TCS.Mapviewer.Template.Associations' | translate }}
                        </div>
                        <div
                            class="relationship"
                            *ngIf="!spatialItem.workspaces.length"
                            translate="TCS.Mapviewer.Template.NoAssociations"
                        ></div>
                    </div>
                    <div class="layer-edit" *ngIf="spatialItem.id">
                        <gsp-button
                            [type]="ButtonType.Icon"
                            title="{{ 'TC.Common.Edit' | translate }}"
                            (action)="editTemplate(spatialItem.id)"
                            iconClass="icon_line_template i24"
                        >
                        </gsp-button>
                    </div>
                </li>
            </ul>
            <loading *ngIf="paginationLoading && !loading"></loading>
        </custom-scroll>
    </section>

    <footer>
        <gsp-checkbox
            *ngIf="selectedTemplateMappingsArr.length"
            [(value)]="duplicate"
            [id]="'duplicateTemplate'"
            [label]="'TC.Common.DuplicateTemplate' | translate"
        ></gsp-checkbox>
        <div class="footer-action-buttons">
            <gsp-button
                [type]="ButtonType.Default"
                (action)="closeSidePanel()"
                text="{{ 'cancel' | translate }}"
            ></gsp-button>
            <gsp-button
                [type]="ButtonType.Primary"
                (action)="addTemplates()"
                [disabled]="!selectedTemplateMappingsArr.length"
                *ngIf="!duplicate"
                text="{{ 'TC.Common.Use' | translate }}"
            ></gsp-button>
            <gsp-button
                [type]="ButtonType.Primary"
                (action)="addTemplates()"
                [disabled]="!selectedTemplateMappingsArr.length"
                *ngIf="duplicate"
                text="{{
                    (selectedTemplateMappingsArr.length > 1
                        ? 'TC.Common.DuplicateTemplate.Plural'
                        : 'TC.Common.Duplicate'
                    ) | translate
                }}"
            >
            </gsp-button>
        </div>
    </footer>
</section>

<mat-menu #filterContextMenu="matMenu" [hasBackdrop]="false">
    <div mat-menu-item (click)="setSearchMode(SearchMode.TEMPLATE_NAME, $event)" class="chkbox-div search-filter-item">
        <input class="chkbox" type="checkbox" [ngModel]="searchModeDict[SearchMode.TEMPLATE_NAME]" />
        <label translate="TC.MetadataEditor.TemplateName"></label>
    </div>
    <div mat-menu-item (click)="setSearchMode(SearchMode.WORKSPACE_NAME, $event)" class="chkbox-div search-filter-item">
        <input class="chkbox" type="checkbox" [ngModel]="searchModeDict[SearchMode.WORKSPACE_NAME]" />
        <label translate="TCS.Workspace"></label>
    </div>
</mat-menu>

<gsp-popup
    class="link-template-popup"
    [size]="ModalSize.ExtraSmall"
    *ngIf="showLinkTemplatesPopup"
    [heading]="
        duplicate ? 'TCS.AddTemplates.Dialog.HeaderText.Duplicating' : 'TCS.AddTemplates.Dialog.HeaderText.Linking'
    "
    [showDone]="erroredTemplates.length && templatesProcessed === selectedTemplateMappingsArr.length"
    (done)="closeSidePanel()"
    [doneBtnTitle]="'Finish'"
    id="link-template-popup"
>
    <ng-container transclude-popup-content>
        <div *ngIf="!erroredTemplates.length" class="linking-template-process">
            <div class="linking-template-status status-pending">
                <span
                    class="link-status"
                    *ngIf="!duplicate"
                    translate
                    [translateParams]="{
                        templatesProcessed: templatesProcessed + 1,
                        totalTemplates: selectedTemplateMappingsArr.length
                    }"
                    >TCS.AddTemplates.Dialog.LinkingStatus</span
                >
                <span
                    class="link-status"
                    *ngIf="duplicate"
                    translate
                    [translateParams]="{
                        templatesProcessed: templatesProcessed + 1,
                        totalTemplates: selectedTemplateMappingsArr.length
                    }"
                    >TCS.AddTemplates.Dialog.DuplicatingStatus</span
                >
                <div class="status">
                    <span>{{ 'TC.Status.Display.Processing' | translate }}</span>
                </div>
            </div>
            <mat-progress-bar
                [mode]="'determinate'"
                [value]="(templatesProcessed / selectedTemplateMappingsArr.length) * 100"
            >
            </mat-progress-bar>
        </div>

        <div *ngIf="erroredTemplates.length" class="linking-template-process with-error">
            <mat-accordion>
                <mat-expansion-panel #errorPanel>
                    <mat-expansion-panel-header [collapsedHeight]="'32px'" [expandedHeight]="'32px'" class="error-line">
                        <div
                            class="linking-template-status status-pending"
                            *ngIf="templatesProcessed < selectedTemplateMappingsArr.length"
                        >
                            <span
                                class="link-status"
                                *ngIf="!duplicate"
                                translate
                                [translateParams]="{
                                    templatesProcessed: templatesProcessed + 1,
                                    totalTemplates: selectedTemplateMappingsArr.length
                                }"
                                >TCS.AddTemplates.Dialog.LinkingStatus</span
                            >
                            <span
                                class="link-status"
                                *ngIf="duplicate"
                                translate
                                [translateParams]="{
                                    templatesProcessed: templatesProcessed + 1,
                                    totalTemplates: selectedTemplateMappingsArr.length
                                }"
                                >TCS.AddTemplates.Dialog.DuplicatingStatus</span
                            >
                            <div class="status">
                                <i class="icon icon_solid_alert_warning i24"></i>
                                <span translate [translateParams]="{ errorCount: erroredTemplates.length }"
                                    >TCS.AddTemplates.Dialog.ProcessingWithErrors</span
                                >
                            </div>
                        </div>
                        <div
                            class="linking-template-status status-complete"
                            *ngIf="templatesProcessed === selectedTemplateMappingsArr.length"
                        >
                            <span
                                class="link-status"
                                *ngIf="!duplicate"
                                translate
                                [translateParams]="{ templatesProcessedSuccessfully: templatesProcessedSuccessfully }"
                                >TCS.AddTemplates.Dialog.LinkedWithErrors</span
                            >
                            <span
                                class="link-status"
                                *ngIf="duplicate"
                                translate
                                [translateParams]="{ templatesProcessedSuccessfully: templatesProcessedSuccessfully }"
                                >TCS.AddTemplates.Dialog.DuplicatedWithErrors</span
                            >
                            <div class="status">
                                <i class="icon icon_solid_alert_warning i24"></i>
                                <span translate [translateParams]="{ erroredCount: erroredTemplates.length }"
                                    >TCS.AddTemplates.Dialog.CompletedWithErrors</span
                                >
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <mat-progress-bar
                        [mode]="'determinate'"
                        [value]="(templatesProcessed / selectedTemplateMappingsArr.length) * 100"
                    ></mat-progress-bar>
                    <div class="link-template-error">
                        <custom-scroll autoHeightDisable="true">
                            <ul>
                                <li class="error-message" *ngFor="let erroredTemplate of erroredTemplates">
                                    <span
                                        *ngIf="!duplicate"
                                        translate
                                        [translateParams]="{ erroredTemplateName: erroredTemplate.name }"
                                        >TCS.AddTemplates.Dialog.FailedTemplateDetails</span
                                    >
                                    <span
                                        *ngIf="duplicate"
                                        translate
                                        [translateParams]="{ erroredTemplateName: erroredTemplate.name }"
                                        >TCS.AddTemplates.Dialog.FailedTemplateDetails.Duplicating</span
                                    >
                                </li>
                            </ul>
                        </custom-scroll>
                    </div>
                </mat-expansion-panel>
                <mat-progress-bar
                    *ngIf="!errorPanel.expanded"
                    [mode]="'determinate'"
                    [value]="(templatesProcessed / selectedTemplateMappingsArr.length) * 100"
                ></mat-progress-bar>
            </mat-accordion>
        </div>
    </ng-container>
</gsp-popup>

import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import {
    ProjectTemplateStreams,
    TemplateWorkspaceMapping
} from 'src/app/shared/common/current-templates/project-template-streams.service';
import { TemplatesStoreService } from 'src/app/shared/common/current-templates/templates-store.service';
import { KeyCodes } from 'src/app/shared/common/key-codes';
import { ContextMenuService } from 'src/app/shared/common/layout/context-menu.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { TemplateLite } from 'src/app/shared/map-data-services/layer/template-lite';

import { SidePanelStreamsService } from '../side-panel-streams.service';
import { SidePanelName } from '../side-panel.component';

export enum SearchMode {
    TEMPLATE_NAME = 'TEMPLATE_NAME',
    WORKSPACE_NAME = 'WORKSPACE_NAME'
}

export enum SearchModePlaceholder {
    DEFAULT = 'TCS.MapViewer.LayerLibrary.Search.Placeholder.Default',
    TEMPLATE_NAME = 'TCS.MapViewer.LayerLibrary.Search.Placeholder.Template',
    WORKSPACE_NAME = 'TCS.MapViewer.LayerLibrary.Search.Placeholder.Workspace'
}

@Component({
    templateUrl: './add-existing-template.component.html',
    selector: 'add-existing-template'
})
export class AddExistingTemplateComponent implements OnInit, OnDestroy {
    // expose ButtonType enum to template
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;

    private destroyed = new Subject<void>();

    private panelName = SidePanelName.EXISTING_TEMPLATE;
    public loading = true;
    public searchControl = new UntypedFormControl();
    private searchText = '';
    public templateLibraryLayers: Layer[] = [];
    public templates: (TemplateWorkspaceMapping & { selected?: boolean })[] = [];
    public selectedTemplateMappingsArr: (TemplateWorkspaceMapping & { selected?: boolean })[] = [];
    public duplicate = false;
    public showLinkTemplatesPopup = false;
    public templatesProcessed = 0;
    public templatesProcessedSuccessfully = 0;
    public erroredTemplates: (TemplateWorkspaceMapping & { selected?: boolean })[] = [];
    private multipleSelectMode = false;
    public SearchMode = SearchMode;
    public searchModeDict: { [searchMode in SearchMode]: boolean } = {
        [SearchMode.TEMPLATE_NAME]: true,
        [SearchMode.WORKSPACE_NAME]: true
    };
    public searchPlaceholder = SearchModePlaceholder.DEFAULT;

    private pageNumber = 1;
    private allPagesLoaded = false;
    public paginationLoading = false;

    @HostListener('document:keydown', ['$event'])
    setMultipleSelectMode(e: KeyboardEvent) {
        if (e.key === KeyCodes.CTRL && !this.multipleSelectMode) {
            this.multipleSelectMode = true;
        }
    }

    @HostListener('document:keyup', ['$event'])
    removeMultipleSelectMode(e: KeyboardEvent) {
        if (e.key === KeyCodes.CTRL && this.multipleSelectMode) {
            this.multipleSelectMode = false;
        }
    }

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    constructor(
        private router: Router,
        private layersStore: LayersStore,
        private templatesStore: TemplatesStoreService,
        private sidePanelStreams: SidePanelStreamsService,
        private mapWorkspacesStore: MapWorkspacesStoreService,
        private projectStream: ProjectStreamService,
        private messaging: MessagingService,
        private projectTemplateStreams: ProjectTemplateStreams,
        private translate: TranslationService,
        private contextMenuService: ContextMenuService
    ) {}

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(takeUntil(this.destroyed), distinctUntilChanged(), debounceTime(700))
            .subscribe((searchByName: string) => {
                this.searchText = searchByName;
                this.loadTemplates();
                this.selectedTemplateMappingsArr = [];
                this.setTemplatesAsSelected();
                this.resetPagination();
            });

        this.projectTemplateStreams.projectTemplateLoadingStream.pipe(takeUntil(this.destroyed)).subscribe(loading => {
            this.loading = loading;

            if (this.projectTemplateStreams.isTemplateLibraryPaginationEndStream.getValue()) {
                this.allPagesLoaded = true;
                this.paginationLoading = false;
            }
        });

        this.projectTemplateStreams.templateLibraryStream.pipe(takeUntil(this.destroyed)).subscribe(templates => {
            this.templates = templates;
        });

        this.loadTemplates();
    }

    ngOnDestroy(): void {
        this.selectedTemplateMappingsArr = [];
        this.destroyed.next();
    }

    public closeSidePanel(): void {
        this.showLinkTemplatesPopup = false;
        if (this.templates) {
            this.templates.forEach(template => (template.selected = false));
        }
        this.sidePanelStreams.closeSidePanel(this.panelName);
    }

    public openContextMenu(event: MouseEvent): void {
        event.stopPropagation();
        this.contextMenuService.toggleContextMenu(this.trigger);
    }

    private loadTemplates(paginate = false): void {
        if (_.uniq(Object.values(this.searchModeDict)).length === 1) {
            this.searchPlaceholder = SearchModePlaceholder.DEFAULT;
            this.projectTemplateStreams.searchTemplateInLibrary(this.searchText, null, this.pageNumber);
        } else if (this.searchModeDict[SearchMode.TEMPLATE_NAME]) {
            this.searchPlaceholder = SearchModePlaceholder.TEMPLATE_NAME;
            this.projectTemplateStreams.searchTemplateInLibrary(
                this.searchText,
                SearchMode.TEMPLATE_NAME,
                this.pageNumber
            );
        } else {
            this.searchPlaceholder = SearchModePlaceholder.WORKSPACE_NAME;
            this.projectTemplateStreams.searchTemplateInLibrary(
                this.searchText,
                SearchMode.WORKSPACE_NAME,
                this.pageNumber
            );
        }

        if (!paginate) {
            this.loading = true;
            this.resetPagination();
        }
    }

    private resetPagination(): void {
        this.pageNumber = 1;
        this.allPagesLoaded = false;

        this.projectTemplateStreams.isTemplateLibraryPaginationEndStream.next(false);
    }

    public selectOrDeselectTemplate(spatialItem: TemplateWorkspaceMapping & { selected?: boolean }): void {
        if (!this.multipleSelectMode) {
            this.selectedTemplateMappingsArr = [spatialItem];
        } else {
            let templateSelectedIndex = _.findIndex(
                this.selectedTemplateMappingsArr,
                template => template.id === spatialItem.id
            );
            if (templateSelectedIndex === -1) {
                this.selectedTemplateMappingsArr.push(spatialItem);
            } else {
                this.selectedTemplateMappingsArr.splice(templateSelectedIndex, 1);
            }
        }
        this.setTemplatesAsSelected();
    }

    private setTemplatesAsSelected(): void {
        const selectedTemplateIds = this.selectedTemplateMappingsArr.map(template => template.id);
        this.templates.forEach(template => {
            if (selectedTemplateIds.includes(template.id)) {
                template.selected = true;
            } else {
                template.selected = false;
            }
        });
    }

    public async addTemplates(): Promise<void> {
        this.showLinkTemplatesPopup = true;
        for (const selectedTemplateMapping of this.selectedTemplateMappingsArr) {
            if (this.duplicate) {
                await this.duplicateTemplate(selectedTemplateMapping)
                    .then(() => {
                        this.templatesProcessedSuccessfully++;
                    })
                    .catch(() => {
                        this.addErrorTemplates(selectedTemplateMapping);
                    });
            } else {
                await this.addToCurrentWorkspace(selectedTemplateMapping)
                    .then(() => {
                        this.templatesProcessedSuccessfully++;
                    })
                    .catch(() => {
                        this.addErrorTemplates(selectedTemplateMapping);
                    });
            }
            this.templatesProcessed++;
        }
        if (!this.erroredTemplates.length) {
            this.closeSidePanel();
            if (this.duplicate) {
                this.messaging.showInfo(
                    this.translate.instant('TCS.AddTemplates.Dialog.Success.Duplicating', {
                        templatesProcessed: this.templatesProcessed
                    })
                );
            } else {
                this.messaging.showInfo(
                    this.translate.instant('TCS.AddTemplates.Dialog.Success.Linking', {
                        templatesProcessed: this.templatesProcessed
                    })
                );
            }
        }
    }

    private addErrorTemplates(selectedTemplate: TemplateWorkspaceMapping & { selected?: boolean }): void {
        this.erroredTemplates.push(selectedTemplate);
    }

    private async addToCurrentWorkspace(selectedTemplateMapping: TemplateWorkspaceMapping): Promise<Layer> {
        // Creating style from template style rather than layer style because for a linked template,
        // its associated layers can have different styles. For consistency, use template style.
        const style = await this.templatesStore.createOrUpdateStyle(selectedTemplateMapping.template as TemplateLite);
        return this.layersStore.createLayer(
            this.projectStream.getCurrentProject().id,
            {
                layerName: selectedTemplateMapping.template.name,
                templateSeriesId: selectedTemplateMapping.seriesId,
                templateId: selectedTemplateMapping.id,
                styleId: style.id,
                layerType: 'TemplateLayer'
            },
            this.mapWorkspacesStore.getCurrentMapWorkspace().id,
            false
        );
    }

    private async duplicateTemplate(selectedTemplateMapping: TemplateWorkspaceMapping): Promise<Layer> {
        // Creating style from template style rather than layer style because for a linked template,
        // its associated layers can have different styles. For consistency, use template style.
        let currentWorkspaceId = this.mapWorkspacesStore.getCurrentMapWorkspace().id;
        const style = await this.templatesStore.createOrUpdateStyle(selectedTemplateMapping.template as TemplateLite);
        const template = await this.templatesStore.createDuplicateTemplateFromId(selectedTemplateMapping.id);
        return this.layersStore.createLayer(
            this.projectStream.getCurrentProject().id,
            {
                layerName: template.name,
                templateSeriesId: template.seriesId,
                styleId: style.id,
                templateId: template.id,
                layerType: 'TemplateLayer'
            },
            currentWorkspaceId
        );
    }

    public editTemplate(templateId: string): void {
        this.router.navigate(['template/editor'], {
            queryParams: { templateId: templateId },
            queryParamsHandling: 'merge'
        });
    }

    public setSearchMode(mode: SearchMode, $event: MouseEvent): void {
        $event.stopPropagation();
        this.searchModeDict[mode] = !this.searchModeDict[mode];
        this.loadTemplates();
    }

    public throttleLoadNextPage = _.throttle(this.loadNextPage.bind(this), 300);

    private loadNextPage(): void {
        if (!this.allPagesLoaded) {
            this.paginationLoading = true;
            this.pageNumber++;
            this.loadTemplates(true);
        }
    }
}
